import './App.css';
import minion1 from './images/minions-1.png';
import minion2 from './images/minions-2.png';
import minion3 from './images/minions-3.png';

function App() {

  window.onwheel = e => {
    if(e.deltaY >= 0){
      window.scrollTo(0,1500);
    } else {
      window.scrollTo(0,0);
    }
  }

  return (
    <div className='page'>
      <div className='first'>
        <h1>Look at my minions projects</h1>
        <h2>Sunil G. - <a href="https://sunil.fr" target="_blank" rel='noreferrer'>https://sunil.fr</a></h2>
        <div className='child'>
          <div className='minion'>
            <img src={minion1} alt='minion1'></img>
          </div>
          <div className='minion'>
            <img src={minion2} alt='minion1'></img>
          </div>
          <div className='minion'>
            <img src={minion3} alt='minion1'></img>
          </div>
        </div>
        <h2 className='leger'>*Minions made from scratch with Adobe Illustrator</h2>
      </div>
      <div className="App">
        <div onClick={() => window.open("https://laplumedevinci.fr")}>
          <div className='box'>
            <h1>Website creation</h1>
            <span>La Plume De Vinci</span>
          </div>
        </div>
        <div onClick={() => window.open("https://www.idcar.lesminions.com/")}>
          <div className='box'>
            <h1>Blockchain Car ID</h1>
            <span>Starton</span>
          </div>
        </div>
        <div onClick={() => window.open("https://ux.lesminions.com/")}>
          <div className='box'>
            <h1>ERC721 UX Website</h1>
            <span>ESILV</span>
          </div>
        </div>
        <div onClick={() => window.open("https://coinbase.lesminions.com/")}>
          <div className='box'>
            <h1>Coinbase API</h1>
            <span>ESILV</span>
          </div>
        </div>
        <div onClick={() => window.open("https://shareit.lesminions.com")}>
          <div className='box'>
            <h1>Social Media Website</h1>
            <span>ESILV</span>
          </div>
        </div>
        <div onClick={() => window.open("https://sunil.fr")}>
          <div className='box'>
            <h1>My Website</h1>
            <span>Me</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
